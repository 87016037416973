import { register } from '@netcentric/component-loader';
import TeaserAnalytics from 'commons/libs/analytics/analytics.teaser.js';
import { selectors, classes } from './batcom-teaser.config';

class Teaser {
  constructor(el) {
    this.el = el;
    this.init();
  }

  init() {
    this.setRefs();
    if (this.teaserContainer.classList.contains(classes.curtain)) {
      this.setCurtainTeaser();
    }
    this.setEventListeners();
  }

  setRefs() {
    this.teaserContainer = this.el.parentElement;
    this.actionLink = this.el.querySelector(selectors.actionLink);
    this.img = this.el.querySelector(selectors.imageImg);
    this.imgContainer = this.el.querySelector(selectors.image);
    this.actionLinkList = this.el.querySelectorAll(selectors.actionLink);
    this.teaserAnalytics = new TeaserAnalytics(this.el);
    this.teaserContentContainer = this.el.querySelector(selectors.teaserContentContainer);
    this.teaserContentDrag = this.teaserContentContainer?.querySelector(selectors.teaserContentDrag);
    this.teaserContent = this.teaserContentContainer?.querySelector(selectors.teaserContent);
    this.isDragging = false;
  }

  setEventListeners() {
    // Make vertical, horizontal, stage & offset teaser fully clickable
    const layoutClasses = [classes.offsetVariation, classes.stageVariation, classes.verticalVariation, classes.horizontalVariation];
    const isClickable = [...this.teaserContainer.classList].some(cl => layoutClasses.includes(cl))

    if(isClickable && this.actionLink) {
        this.el.addEventListener('click', this.goToURL.bind(this));
        this.el.classList.add(classes.isClickable);
    }

    if (this.teaserContainer.classList.contains(classes.curtain)) {
      const options = {
        root: null,
        threshold: 0,
      };

      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
            // Check if the top of the image is above the top of the viewport
            if (entry.boundingClientRect.top < 0) {
              this.teaserContainer.classList.add(classes.curtainFixed);
            } else {
              this.teaserContainer.classList.remove(classes.curtainFixed);
            }
        });
      }, options);
      observer.observe(this.limit);
    }

    if (this.actionLinkList.length > 0) {
      this.actionLinkList.forEach(actionLink => {
        actionLink.addEventListener('click', () => {
          this.teaserAnalytics.trackActionLinkClick(actionLink);
        });
      });
    }

    if (this.el.parentElement.classList.contains(classes.gloQRTeaser)) {
      this.el.addEventListener('click', () => {
        this.slideUpTeaser();
      });
      this.teaserContentDrag.addEventListener('mousedown', (e) => this.dragStart(e));
      document.addEventListener('mousemove', (e) => this.dragging(e));
      document.addEventListener('mouseup', (e) => this.dragStop(e));
      this.teaserContentDrag.addEventListener('touchstart', (e) => this.dragStart(e));
      document.addEventListener('touchmove', (e) => this.dragging(e));
      document.addEventListener('touchend', (e) => this.dragStop(e));
    }
  }

  setCurtainTeaser() {
    // prepend a div to the teaser container
    this.limit = document.createElement('div');
    this.limit.classList.add(classes.curtainLimit);
    this.teaserContainer.prepend(this.limit);
    // move image to background to allow for curtain
    this.img.classList.add(classes.imageHidden);
    this.imgContainer.style.backgroundImage = `url(${this.img.src})`;
    this.imgContainer.classList.add(classes.hasBackground);
  }

  goToURL() {
    this.actionLink.click();
  }

  updateContentHeight(height) {
    this.teaserContent.style.height = `${height}vh`;
  }

  slideUpTeaser() {
    this.teaserContentContainer.classList.add(classes.showContent);
    document.body.style.overflowY = 'hidden';
    this.updateContentHeight(100);
  }

  hideTeaser() {
    this.teaserContentContainer.classList.remove(classes.showContent);
    document.body.style.overflowY = 'auto';
  }

  dragStart(e) {
    this.isDragging = true;
    this.startY = e.pageY || e.touches?.[0].pageY;
    this.startHeight = parseInt(this.teaserContent.style.height, 10);
    this.teaserContentContainer.classList.add(classes.draggingContent);
  }

  dragging(e) {
    if (!this.isDragging) return;
    // eslint-disable-next-line no-unsafe-optional-chaining
    const delta = this.startY - (e.pageY || e.touches?.[0].pageY);
    const newHeight = this.startHeight + delta / window.innerHeight * 100;
    this.updateContentHeight(newHeight);
  }

  dragStop() {
    this.isDragging = false;
    this.teaserContentContainer.classList.remove(classes.draggingContent);
    const contentHeight = parseInt(this.teaserContent.style.height, 10);
    contentHeight < 70 ? this.hideTeaser() : this.updateContentHeight(100);
  }
}
register({ Teaser });
